document.addEventListener("turbo:load", (e) => {
  const navbarButtons = document.querySelectorAll(".euro-navbar-active-container");
  const euroRegisters = document.querySelectorAll(".euro-register");
  const euroEnterCompetitions = document.querySelectorAll(".euro-show-tournament-enter-button");
  const euroMyTeams = document.querySelectorAll(".euro-my-team");
  const euroMyEntriesSliders = document.querySelectorAll(".euro-my-entries-slider")
  const loadingModal = document.querySelector(".euro-loading-modal");
  const euroMyMiniLeagues = document.querySelectorAll(".euro-mini-league-container");
  const euroUserMenuButton = document.querySelectorAll(".euro-top-part-user-menu-icon-container");
  const euroUserMenuBuyCoinsButton = document.querySelectorAll(".euro-user-menu-buy-coins-button");
  const euroStageContainers = document.querySelectorAll(".euro-stage-container");
  const euroTopPartBuyCoins = document.querySelectorAll(".euro-top-part-buy-coins-cointainer");
  const buyCoinsButtons = document.querySelectorAll(".euro-top-part-buy-coins-container");
  const euroLeaderboardTeamContainers = document.querySelectorAll(".euro-leaderboard-team-container");
  const getCoinsButtons = document.querySelectorAll(".get-coins-buy-button");

  if (navbarButtons.length > 0) {
    navigationLoading(navbarButtons, loadingModal)
  }
  if (euroRegisters.length > 0) {
    navigationLoading(euroRegisters, loadingModal)
  }
  if (euroEnterCompetitions.length > 0) {
    navigationLoading(euroEnterCompetitions, loadingModal)
  }

  if (euroMyTeams.length > 0) {
    navigationLoading(euroMyTeams, loadingModal)
  }

  if (euroMyEntriesSliders.length > 0) {
    navigationLoading(euroMyEntriesSliders, loadingModal)
  }

  if (euroMyMiniLeagues.length > 0) {
    navigationLoading(euroMyMiniLeagues, loadingModal)
  }

  if (euroUserMenuButton.length > 0) {
    navigationLoading(euroUserMenuButton, loadingModal)
  }

  if (euroUserMenuBuyCoinsButton.length > 0) {
    navigationLoading(euroUserMenuBuyCoinsButton, loadingModal)
  }

  if (euroStageContainers.length > 0) {
    navigationLoading(euroStageContainers, loadingModal)
  }

  if (euroTopPartBuyCoins.length > 0) {
    navigationLoading(euroTopPartBuyCoins, loadingModal)
  }

  if (buyCoinsButtons.length > 0) {
    navigationLoading(buyCoinsButtons, loadingModal)
  }

  if (euroLeaderboardTeamContainers.length > 0) {
    navigationLoading(euroLeaderboardTeamContainers, loadingModal)
  }

  if (getCoinsButtons.length > 0) {
    navigationLoading(getCoinsButtons, loadingModal)
  }




  function navigationLoading(allButtons, loadingModal) {
    allButtons.forEach(allButton => {
      allButton.addEventListener("click", (e) => {
        loadingModal.style.display = "flex";
      })
    });
  }
})
