document.addEventListener("turbo:load", () => {
  const closeAndroidAppButton = document.querySelector(".euro-close-android-app");
  const downloadAndroidAppContainer = document.querySelector(".euro-get-app-container");

  if (downloadAndroidAppContainer) {
    closeAndroidAppButton.addEventListener("click", (e) => {
      downloadAndroidAppContainer.style.display = "none";
    })
  }
})
